import React, { useEffect, useState } from "react";
import useGetAPageData from "../hooks/webpagepreview/useGetPageData";
import { useLocation } from "@reach/router";
import Layout from "../layout/Layout";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import Components from "../components/dynamic";
import { staticPage } from "../../config/staticPage";

const PreviewPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const webpageId = params.get("webpage_id");
  const token = params.get("token");

  const [page, setPage] = useState(null);

  useEffect(() => {
    const GetPageData = async () => {
      const data = await useGetAPageData(webpageId, token);
      setPage(data);
    };
    GetPageData();
  }, [webpageId]);

  const components = page?.versions?.components || [] || [];

  return (
    <>
      <div className="container">
        <Breadcrumbs location={location} url={page?.url} title={page?.name} />
      </div>
      {components.length
        ? components.map((item) => {
            return <Components data={item} />;
          })
        : null}
    </>
  );
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "preview-page") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

PreviewPage.Layout = Layout;
export default PreviewPage;
