async function useGetAPageData(webpageId, token) {
  const url = `${process.env.GATSBY_ADMIN_API_URL}/cms/webpages/${process.env.GATSBY_API_TENANT_ID}/${process.env.GATSBY_API_STORE_ID}/pages?webpage_id=${webpageId}`;
  const data = await getPage(url, token);

  if (!data) {
    return [];
  }
  return data[0];
}

async function getPage(url, token) {
  const page = await fetch(url, {
    method: "GET",
    headers: {
      http_origin: `${process.env.GATSBY_ADMIN_ORIGIN}`,
      'X-AUTH-TOKEN': `${token}`,
      'x-store-id': `${process.env.GATSBY_API_STORE_ID}`,
      'X-TENANT-ID': `${process.env.GATSBY_API_TENANT_ID}`
    },
  })
    .then((i) => i.json())
    .catch((e) => console.error("e -> ", e));
  return page;
}

export default useGetAPageData;
